<template>
    <div class="timers" :class="[$mq]">
        <Topbar :categories="categories" @selectCategory="selectCategory"></Topbar>

        <div id="board">
            <Column v-for="column in columns" :key="column.id" :data="column" :timers="columnTimers[column.id] ? columnTimers[column.id] : []"></Column>
        </div>
    </div>
</template>

<script>
import Column from '../components/domain/timerproduct/column.vue'
import Topbar from '../components/domain/timerproduct/topbar.vue'

export default {
    components: { Column, Topbar },
    name: 'TimerProduct',
    data() {
        return {
            timers: [],
            selectedCategory: false
        }
    },
    computed: {
        columns() {
            return this.$store.getters['timerproduct/getStates']
        },
        categories() {
            return this.$store.getters['timerproduct/getCategories']
        },
        columnTimers: function () {
            return this.$store.getters['timerproduct/getTimersByCategory'](this.selectedCategory)
        }
    },
    methods: {
        load() {
            let self = this

            this.$overlay.loading()

            this.$store.dispatch('timerproduct/loadSchema').then(() => {
                this.$store.dispatch('timerproduct/loadTimers').then(() => {
                    self.selectCategory(false)
                    self.$overlay.hide()
                })
            })
        },
        selectCategory(category) {
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('timerproduct/selectCategory', category).then(() => {
                self.selectedCategory = category
                self.$overlay.hide()
            })
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
.timers {
    #board {
        padding: 8px;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        display: grid;
        position: absolute;
        height: calc(100% - 54px);
        grid-template-columns: 1fr 1fr 1fr;
        min-height: 0;
        min-width: 0;
    }
}
</style>
