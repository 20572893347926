<template>
    <div class="topbar" :class="[$mq]">
        <div id="categories">
            <div class="option" v-for="category in sortedCategories" :class="[category.color, { selected: category.selected }]" @click="selectCategory(category.id)">
                <span class="text">{{ category.name }}</span>
                <span v-if="category.qty > 0" class="quantity">{{ category.qty }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: { type: Object }
    },
    data() {
        return {}
    },
    computed: {
        sortedCategories() {
            return [
                this.categories[false],
                ...Object.values(this.categories)
                    .filter((item) => item.id !== false)
                    .sort((a, b) => a.id - b.id)
            ]
        }
    },
    methods: {
        selectCategory(category) {
            this.$emit('selectCategory', category)
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.topbar {
    height: 54px;
    padding: 10px;

    #categories {
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        @include border-radius(4px);
        width: calc(100%);
        max-width: 800px;
        margin: 0 auto;
        padding: 3px;
        background-color: #fff;

        .option {
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            @include border-radius(4px);
            width: 49%;
            text-align: center;
            height: 34px;
            cursor: pointer;
            padding: 5px;
            text-transform: uppercase;

            &.selected {
                font-family: $text-sbold;
                background-color: $main-t80;
                color: $main-dark;
                cursor: default;

                .text {
                    font-family: Avenir-Bold;
                    color: $main-dark;
                }
            }
            &:hover:not(.selected) {
                @include bgHover(#fff);
            }

            .text {
                @include font-size(sm);
                @include text-ellipsis();
                font-family: $text;
                color: $neutro-s50;
                text-align: center;
                padding: 0 10px;

                &.selected {
                    font-family: $text-sbold;
                    background-color: $main-t80;
                    color: $main-dark;
                    cursor: default;
                }
            }

            .quantity {
                background-color: $inactive;
                padding: 3px 6px;
                font-size: 14px;
                border-radius: 4px;
                font-family: Avenir-Bold;
                background-color: $color-error-500;
                color: #fff;
            }
        }
    }
}
</style>
